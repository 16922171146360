@import "trix/dist/trix";

.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  .attachment[data-trix-content-type="application/vnd.trix.horizontal-rule.html"] {
    display: block;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.6rem;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }

  ul {
    list-style: inherit;

    li {
      margin-left: 15px;
    }
  }
}
