@import 'animations.scss';

.has-text-primary-light {color: $primary_light}
.has-text-primary-dark {color: $primary_dark}
.has-text-secondary-light {color: $secondary_light}
.has-text-secondary-dark {color: $secondary_dark}

.has-text-nowrap {
  white-space: nowrap;
}

.has-text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.has-background-primary-light {background-color: $primary_light}
.has-background-primary-dark {background-color: $primary_dark}
.has-background-secondary-light {background-color: $secondary_light}
.has-background-secondary-dark {
  background-color: $secondary_dark;
  color: white !important;
}

.has-background-primary-light-25 {
  background-color: $primary_dark_25;
}

.has-text-vertical-middle {
  vertical-align: middle !important;
}

.no-selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

strong {
  color: inherit;
}

.opacity-0 {
  opacity: 0 !important;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width:99vw;
  display: none;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, .2);
  z-index: 900;
}
.overlay.active {
  display: block !important;
}
.modal.checkout {
  position: fixed;
}

div.checkout-twint-logo {
  width: 1.8em;

  img.checkout-twint-logo {
    height: 1.7rem;
  }
}

div.checkout-postfinance-logo {
  width: 1.7em;

  img.checkout-postfinance-logo {
    height: 1.5rem;
  }
}

article.item-checkout {

  div.media-content {
    display: flex;
    justify-content: space-between;

    div.content {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      height: 100%;

      div.details {
        flex-grow: 1;
      }
    }

    div.qts-total {
      height: 100%;

      div:first-child {
        width: 100%;
      }
      .price-total {
        width: 200px;
        white-space: nowrap;
      }
    }
  }

  button.remove {
    font-size: 0.65rem;

    svg {
      margin-top: -2px;
    }
  }
}

@include mobile {
  article.item-checkout {
    .price-total {
      font-size: .9rem !important;
      padding-top: 5px;
      width: auto !important;
    }

    div.media-content {
      flex-direction: column;

      div.content {
        margin-bottom: 0.25em;
      }
    }
  }
}

.modal {
  position: fixed !important;
}
.modal-card-head {
  padding: 10px;
}
.modal-card-foot {
  justify-content: flex-end;
  padding: 10px;
}

.hero.header {
  //background: url('/images/start_header/start_header.webp'), url('../images/start_header_default.webp');
  transition: background-image 2s;
  position: relative;

  .hero-body {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .header-logo {
    max-width: 50vw;
    max-height: calc(100vh - 45rem);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .subtitle {
    text-shadow: 0 1px 2px #ccc;
  }

  .product, .category {
    min-height: 40vh;
  }
}

.hero.header.start {
  height: calc(100vh - 240px);

  .hero-body {
    padding-top: unset;
    padding-bottom: unset;

    div.container {
      height: 100%;
    }
  }

  video {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    max-width: unset !important;
    min-width: 100%;
  }
}

.hero.start-gallery {
  .columns {
    margin: -0.5rem 0 -0.5rem 0 !important;

    .column {
      padding: 0.5rem !important;
    }

    .column:first-child {
      padding: 0.5rem 0.5rem 0.5rem 0 !important;
    }

    .column:last-child {
      padding: 0.5rem 0 0.5rem 0.5rem !important;
    }
  }
}

.hero.start-list {
  .columns {
    margin: -0.5rem 0 -0.5rem 0 !important;
  }
}

.hero.header.static {
  background-image: url('/images/start_header/start_header.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.hero-carousel {
  .slider {
    .slider-container {
      .slider-item {
        min-width: 100vw !important;
        height: 100%;

        img {
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }
    }
    .slider-navigation-previous, .slider-navigation-next, .slider-pagination {
      display: none;
    }
  }
}

.navbar-link:not(.is-arrowless)::after {
  border-color: $primary_dark !important;
}

@media screen and (min-width: 1921px) {
  .hero.header {
    .product, .category {
      min-height: 50vh;
    }
  }
}

.hero.start-footer {
  background: url('../images/start_footer.webp'), url('../images/start_footer_default.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0px;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .container {
    text-shadow: 0 1px 2px #000;
  }
}

section.hero.static {
  background: url('../images/static-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0px;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .container {
    text-shadow: 0 1px 2px #000;
  }
}

.smaller {
  font-size: .9rem;
}

#dropdown-menu-cart.dropdown-menu {
  min-width: 20rem;

  .dropdown-content {
    button {
      width: 100%;
    }

    p.image {
      padding: 2px;

      img {
        height: auto;
        width: auto;
        max-height: unset;
        margin: auto;
      }
    }
  }
}
.cart-item-counter {
  font-size: .6rem !important;
  border-radius: 12px !important;
}

.nav-categories {
  z-index: 6;
}

.navbar.cookies {
  height: auto;
}

.cart-notification {
  position: fixed;
  bottom: 10vh;
  z-index: 1000;
  width: 80%;
  margin-left: 10%;
}

div.cart-menu-wrapper {
  position: fixed;
  top: 0;
  right: -40rem;
  width: 30rem;
  max-width: 80vw;
  height: 100vh;
  padding-top: 52px;
  z-index: 1000;
  background-color: white;
  padding: 1rem;
  overflow-y: auto;
  box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.35);
  transition: all 0.3s;
}

div.cart-menu-wrapper.active {
  right: 0;
}

.navbar {
  box-shadow: 0 0 0.125em 0 rgba(0, 0, 0, 0.35);
  height: 52px;
}

/*
.navbar-dropdown {
  max-height: 50vh;
}
*/

/* Navbar Megamenu */
.navbar-item.is-mega {
  position: static;

  .is-mega-menu-title {
    margin-bottom: 0;
    padding: .375rem 1rem;
  }
}

.search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B5B5B5;
  opacity: 1; /* Firefox */
}

.search:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B5B5B5;
}

.search::-ms-input-placeholder { /* Microsoft Edge */
  color: #B5B5B5;
}

.dropdown-content {
  overflow-y: auto;
  max-height: inherit;

  #header_cart_items {
    max-height: 80%;
  }
}

.m-auto {
  margin: auto !important;
}

.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}

//Buttons
.button {
  /* Ignore click on icon */
  i.fas, i.far {
    pointer-events: none;
  }

  svg {
    pointer-events: none;
  }
}

.is-clickable {
  cursor: pointer;
}

.is-clickable-not {
  pointer-events: none;
}

.strikethrough {
  text-decoration: line-through;
}

/*
  Categories
*/
.box.category {
  img {
    border-radius: 6px;
  }

  div.is-overlay {
    display: flex;
    flex-direction: column-reverse;

    div.name {
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 3rem;
      font-size: 1.3rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 5px 10px;
      line-height: calc(3rem - 50%);
    }
  }
}

.card.category {
  .card-image {
    figure {
      margin: auto;

      img {
        height: auto;
        width: auto;
        //min-height: 100%;
        min-width: 100%;
      }
    }
  }
}

.category.description-long {
  figure {
    text-align: center;
  }
}

/*
  Products
*/


/*
  Content
*/

.card {
  border-radius: 6px;
  -moz-border-radius: 6px;
  //border: 1px solid #b0b435;
  box-shadow: 0;
}

div.card.product, div.card.category {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-image {
    figure {
      margin: auto;

      img {
        height: auto;
        width: auto;
        //min-height: 100%;
        min-width: 100%;
      }
    }
  }

  a, a:visited {
    color: inherit;
  }

  .description {
    font-size: .9rem;
  }
}

div.product.image {
  width: 100%;
  height: auto;
  background-color: white;
  border: 1px solid $primary;
  padding: 3px;

  figure {
    margin: auto;
    width: 100%;
  }
}

div.hero-body.product {

  .description {
    font-size: .9rem;

    ul {
      list-style: inherit;

      li {
        margin-left: 15px;
      }
    }
  }

  div.tabs {
    height: 42px;

    ul {
      border-bottom-color: hsl(0deg, 0%, 86%);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  }

  .images {
    color: $primary_dark;
  }
}

figure.image.zoom, img.zoom {
  transition: transform .4s;
}
figure.image.zoom:hover, img.zoom:hover {
  transform: scale(1.1);
}

figure.image.product {
  span.action {
		position: absolute;
		top: calc(50% - 24px);
		color: #aaa;
    font-size: .7rem;
	}
  span.action.left {
		left: '5px';
	}
	span.action.zoom {
		right: calc(50% - 32px);
	}
  span.action.right {
		right: 0;
	}
  .fa-circle {
    color: $primary_dark
  }
  svg {
    pointer-events: none;
  }
}

figure.video.product {
	display: inline-block;
  position: relative;

	svg {
		position: absolute;
		top: calc(50% - 16px);
		right: calc(50% - 16px);
		color: #aaa;
		pointer-events: none;
	}
}

div.product.images {
  max-width: 400px;
  margin-top: 0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  div {
    width: 125px;
    height: 125px;
    border: 1px solid $secondary_light;
    padding: 1px;
    background-color: white;
    margin: 0.25rem;

    img {
      margin: auto;
    }
  }
}

.product-picture-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  padding: 20px;
  //text-align: center;
  //cursor: pointer;
  //cursor: hand;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    text-align: center;
    margin: auto;
    opacity: 1;
    //border: 1px solid white;
    //padding: 1px;
    max-width: 80vw;
    max-height: calc(80vh - 80px);
  }

  .thumbnails {
    img {
      text-align: center;
      margin: auto;
      opacity: 1;
      border: 1px solid white;
      padding: 1px;
      max-width: 8vw;
      max-height: 8vh;
    }

    img.active {
      border-color: $primary;
    }
  }
}

.product-video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  cursor: hand;
  display: flex;
  flex-direction: column;
  justify-content: center;

  video {
    text-align: center;
    margin: auto;
    opacity: 1;
    border: 1px solid white;
    padding: 1px;
    max-width: 80%;
    max-height: 80%;
  }
}

table.product-details {
  background-color: transparent;
}

div.product {
  div.price {

    p.price {
      font-size: 1.5rem;
      font-weight: 600;
    }
    
    @include mobile {
      div:first-child {
        flex-direction: column;

        div.add_to_cart, p.price {
          align-self: flex-start;

          div {
            flex-direction: row;
          }
        }
      }

      div.bulk_price {
        font-size: 95% !important;
      }
    }
  }
}

/*
  Footer
*/
.footer {
  padding: 4rem 1.5rem 2rem;
}
footer strong {
  color: inherit;
}

footer .menu-list a {
  color: hsl(0, 0%, 98%);
  font-size: 0.75rem;
}
footer .menu-list a:hover {
  background-color: inherit;
  color: inherit;
}

@media screen and (min-width: 1024px) {

}

@media screen and (max-width: 1215px) {

}

@media screen and (max-width: 1407px) {}

@media screen and (min-width: 1216px) {
  .start.tile {

    .tile.is-parent {
      max-width: 50%;
    }
  }
}

@media screen and (min-width: 1408px) {
  .start.tile {

    .tile.is-parent {
      max-width: 33%;
    }
  }
}
