@charset "UTF-8";
@import 'bulma/css/bulma.min.css';
@import "bulma-carousel/dist/css/bulma-carousel.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.steps:not(:last-child) {
  margin-bottom: 1.5rem;
}

.steps .steps-segment {
  position: relative;
}
.steps .steps-segment:not(:last-child):after {
  content: " ";
  display: block;
  position: absolute;
}
@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal) .steps-segment {
    display: block;
  }
  .steps:not(.is-horizontal) .steps-segment:not(:last-child) {
    min-height: 4em;
  }
  .steps:not(.is-horizontal).is-short .steps-segment {
    flex-grow: 0;
  }
  .steps:not(.is-horizontal).is-short.is-centered {
    justify-content: center;
  }
  .steps:not(.is-horizontal).is-short.is-bottom {
    justify-content: flex-end;
  }
  .steps:not(.is-horizontal):not(.is-short) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .steps:not(.is-horizontal):not(.is-short) .steps-segment:not(:last-child) {
    flex-grow: 1;
  }
}
.steps.is-vertical .steps-segment {
  display: block;
}
.steps.is-vertical .steps-segment:not(:last-child) {
  min-height: 4em;
}
.steps.is-vertical.is-short .steps-segment {
  flex-grow: 0;
}
.steps.is-vertical.is-short.is-centered {
  justify-content: center;
}
.steps.is-vertical.is-short.is-bottom {
  justify-content: flex-end;
}
.steps.is-vertical:not(.is-short) {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.steps.is-vertical:not(.is-short) .steps-segment:not(:last-child) {
  flex-grow: 1;
}
@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical) {
    display: flex;
  }
  .steps:not(.is-vertical) .steps-segment:not(:last-child) {
    flex-basis: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .steps:not(.is-vertical).is-narrow .steps-segment {
    flex-grow: 0;
  }
  .steps:not(.is-vertical).is-narrow .steps-segment:not(:last-child) {
    min-width: 10em;
  }
  .steps:not(.is-vertical).is-narrow.is-centered {
    justify-content: center;
  }
  .steps:not(.is-vertical).is-narrow.is-right {
    justify-content: flex-end;
  }
  .steps:not(.is-vertical).has-content-centered {
    text-align: center;
  }
  .steps:not(.is-vertical).is-balanced .steps-segment:last-child, .steps:not(.is-vertical).has-content-centered .steps-segment:last-child {
    flex-basis: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .steps:not(.is-vertical).is-narrow.has-content-centered .steps-segment:last-child {
    flex-grow: 0;
    min-width: 10em;
  }
  .steps:not(.is-vertical) .steps-content.is-divider-content {
    text-align: center;
  }
}
.steps.is-horizontal {
  display: flex;
}
.steps.is-horizontal .steps-segment:not(:last-child) {
  flex-basis: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
}
.steps.is-horizontal.is-narrow .steps-segment {
  flex-grow: 0;
}
.steps.is-horizontal.is-narrow .steps-segment:not(:last-child) {
  min-width: 10em;
}
.steps.is-horizontal.is-narrow.is-centered {
  justify-content: center;
}
.steps.is-horizontal.is-narrow.is-right {
  justify-content: flex-end;
}
.steps.is-horizontal.has-content-centered {
  text-align: center;
}
.steps.is-horizontal.is-balanced .steps-segment:last-child, .steps.is-horizontal.has-content-centered .steps-segment:last-child {
  flex-basis: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
}
.steps.is-horizontal.is-narrow.has-content-centered .steps-segment:last-child {
  flex-grow: 0;
  min-width: 10em;
}
.steps.is-horizontal .steps-content.is-divider-content {
  text-align: center;
}
.steps .steps-marker {
  align-items: center;
  display: flex;
  border-radius: 50%;
  font-weight: 700;
  justify-content: center;
  position: relative;
  z-index: 5;
}

.steps {
  font-size: 1rem;
}
.steps .steps-marker {
  height: 2rem;
  width: 2rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal) {
    display: flex;
    flex-direction: column;
  }
  .steps:not(.is-horizontal) .steps-segment:not(:last-child):after {
    bottom: -0.24rem;
    left: calc(1rem - (0.2em));
    top: 1.76rem;
    width: 0.4em;
  }
  .steps:not(.is-horizontal) .steps-content {
    margin-left: calc(1rem + .5em);
    margin-top: -2rem;
    padding-left: 1em;
    padding-bottom: 1em;
  }
  .steps:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }
  .steps:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1rem - .5em);
  }
  .steps:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 2.3rem;
    bottom: 0.3rem;
  }
}
.steps.is-vertical {
  display: flex;
  flex-direction: column;
}
.steps.is-vertical .steps-segment:not(:last-child):after {
  bottom: -0.24rem;
  left: calc(1rem - (0.2em));
  top: 1.76rem;
  width: 0.4em;
}
.steps.is-vertical .steps-content {
  margin-left: calc(1rem + .5em);
  margin-top: -2rem;
  padding-left: 1em;
  padding-bottom: 1em;
}
.steps.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}
.steps.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1rem - .5em);
}
.steps.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 2.3rem;
  bottom: 0.3rem;
}
@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: 0.4em;
    left: 1.76rem;
    right: -0.24rem;
    top: calc(1rem - (0.2em));
  }
  .steps:not(.is-vertical).has-content-above .steps-segment:not(:last-child)::after {
    bottom: calc(1rem - (0.2em));
    top: auto;
  }
  .steps:not(.is-vertical) .extra-data {
    left: calc(50% + 1.3rem);
    right: calc(-50% + 1.3rem);
    bottom: 100%;
    position: absolute;
  }
  .steps:not(.is-vertical) .extra-data.has-overflow-right {
    overflow: visible;
    right: auto;
    white-space: nowrap;
    min-width: calc(100% - 1rem - (0.2em));
  }
  .steps:not(.is-vertical) .extra-data.has-overflow-left {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1rem - (0.2em));
  }
  .steps:not(.is-vertical) .extra-data.has-overflow-centered {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1rem - (0.2em));
  }
  .steps:not(.is-vertical).has-content-above .extra-data {
    top: 100%;
    bottom: auto;
  }
  .steps:not(.is-vertical) .steps-content {
    margin-left: 1rem;
  }
  .steps:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1rem;
  }
  .steps:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1rem;
    padding-left: 2em;
    padding-right: 2em;
  }
  .steps:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }
  .steps:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1rem);
  }
  .steps:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 2rem;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 0.2em;
  }
  .steps:not(.is-vertical).has-content-above.has-content-centered .steps-content {
    margin-bottom: 2rem;
    padding-bottom: 0.2em;
  }
  .steps:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 2.3rem;
    right: 0.3rem;
  }
  .steps:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.3rem);
    right: calc(-50% + 1.3rem);
  }
}
.steps.is-horizontal .steps-segment:not(:last-child):after {
  height: 0.4em;
  left: 1.76rem;
  right: -0.24rem;
  top: calc(1rem - (0.2em));
}
.steps.is-horizontal.has-content-above .steps-segment:not(:last-child)::after {
  bottom: calc(1rem - (0.2em));
  top: auto;
}
.steps.is-horizontal .extra-data {
  left: calc(50% + 1.3rem);
  right: calc(-50% + 1.3rem);
  bottom: 100%;
  position: absolute;
}
.steps.is-horizontal .extra-data.has-overflow-right {
  overflow: visible;
  right: auto;
  white-space: nowrap;
  min-width: calc(100% - 1rem - (0.2em));
}
.steps.is-horizontal .extra-data.has-overflow-left {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1rem - (0.2em));
}
.steps.is-horizontal .extra-data.has-overflow-centered {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1rem - (0.2em));
}
.steps.is-horizontal.has-content-above .extra-data {
  top: 100%;
  bottom: auto;
}
.steps.is-horizontal .steps-content {
  margin-left: 1rem;
}
.steps.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1rem;
}
.steps.is-horizontal .steps-content.is-divider-content {
  margin-right: -1rem;
  padding-left: 2em;
  padding-right: 2em;
}
.steps.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}
.steps.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1rem);
}
.steps.is-horizontal.has-content-centered .steps-content {
  margin-top: 2rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 0.2em;
}
.steps.is-horizontal.has-content-above.has-content-centered .steps-content {
  margin-bottom: 2rem;
  padding-bottom: 0.2em;
}
.steps.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 2.3rem;
  right: 0.3rem;
}
.steps.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.3rem);
  right: calc(-50% + 1.3rem);
}

.steps.is-small {
  font-size: 0.75rem;
}
.steps.is-small .steps-marker {
  height: 1.5rem;
  width: 1.5rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .steps.is-small:not(.is-horizontal) {
    display: flex;
    flex-direction: column;
  }
  .steps.is-small:not(.is-horizontal) .steps-segment:not(:last-child):after {
    bottom: -0.24rem;
    left: calc(0.75rem - (0.2em));
    top: 1.26rem;
    width: 0.4em;
  }
  .steps.is-small:not(.is-horizontal) .steps-content {
    margin-left: calc(0.75rem + .5em);
    margin-top: -1.5rem;
    padding-left: 1em;
    padding-bottom: 1em;
  }
  .steps.is-small:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }
  .steps.is-small:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(0.75rem - .5em);
  }
  .steps.is-small:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 1.8rem;
    bottom: 0.3rem;
  }
}
.steps.is-small.is-vertical {
  display: flex;
  flex-direction: column;
}
.steps.is-small.is-vertical .steps-segment:not(:last-child):after {
  bottom: -0.24rem;
  left: calc(0.75rem - (0.2em));
  top: 1.26rem;
  width: 0.4em;
}
.steps.is-small.is-vertical .steps-content {
  margin-left: calc(0.75rem + .5em);
  margin-top: -1.5rem;
  padding-left: 1em;
  padding-bottom: 1em;
}
.steps.is-small.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}
.steps.is-small.is-vertical.has-content-centered .steps-content {
  padding-top: calc(0.75rem - .5em);
}
.steps.is-small.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 1.8rem;
  bottom: 0.3rem;
}
@media screen and (min-width: 769px), print {
  .steps.is-small:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: 0.4em;
    left: 1.26rem;
    right: -0.24rem;
    top: calc(0.75rem - (0.2em));
  }
  .steps.is-small:not(.is-vertical).has-content-above .steps-segment:not(:last-child)::after {
    bottom: calc(0.75rem - (0.2em));
    top: auto;
  }
  .steps.is-small:not(.is-vertical) .extra-data {
    left: calc(50% + 1.05rem);
    right: calc(-50% + 1.05rem);
    bottom: 100%;
    position: absolute;
  }
  .steps.is-small:not(.is-vertical) .extra-data.has-overflow-right {
    overflow: visible;
    right: auto;
    white-space: nowrap;
    min-width: calc(100% - 0.75rem - (0.2em));
  }
  .steps.is-small:not(.is-vertical) .extra-data.has-overflow-left {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 0.75rem - (0.2em));
  }
  .steps.is-small:not(.is-vertical) .extra-data.has-overflow-centered {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 0.75rem - (0.2em));
  }
  .steps.is-small:not(.is-vertical).has-content-above .extra-data {
    top: 100%;
    bottom: auto;
  }
  .steps.is-small:not(.is-vertical) .steps-content {
    margin-left: 0.75rem;
  }
  .steps.is-small:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -0.75rem;
  }
  .steps.is-small:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -0.75rem;
    padding-left: 2em;
    padding-right: 2em;
  }
  .steps.is-small:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }
  .steps.is-small:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 0.75rem);
  }
  .steps.is-small:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 1.5rem;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 0.2em;
  }
  .steps.is-small:not(.is-vertical).has-content-above.has-content-centered .steps-content {
    margin-bottom: 1.5rem;
    padding-bottom: 0.2em;
  }
  .steps.is-small:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 1.8rem;
    right: 0.3rem;
  }
  .steps.is-small:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-small:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.05rem);
    right: calc(-50% + 1.05rem);
  }
}
.steps.is-small.is-horizontal .steps-segment:not(:last-child):after {
  height: 0.4em;
  left: 1.26rem;
  right: -0.24rem;
  top: calc(0.75rem - (0.2em));
}
.steps.is-small.is-horizontal.has-content-above .steps-segment:not(:last-child)::after {
  bottom: calc(0.75rem - (0.2em));
  top: auto;
}
.steps.is-small.is-horizontal .extra-data {
  left: calc(50% + 1.05rem);
  right: calc(-50% + 1.05rem);
  bottom: 100%;
  position: absolute;
}
.steps.is-small.is-horizontal .extra-data.has-overflow-right {
  overflow: visible;
  right: auto;
  white-space: nowrap;
  min-width: calc(100% - 0.75rem - (0.2em));
}
.steps.is-small.is-horizontal .extra-data.has-overflow-left {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 0.75rem - (0.2em));
}
.steps.is-small.is-horizontal .extra-data.has-overflow-centered {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 0.75rem - (0.2em));
}
.steps.is-small.is-horizontal.has-content-above .extra-data {
  top: 100%;
  bottom: auto;
}
.steps.is-small.is-horizontal .steps-content {
  margin-left: 0.75rem;
}
.steps.is-small.is-horizontal .steps-content:not(:last-child) {
  margin-right: -0.75rem;
}
.steps.is-small.is-horizontal .steps-content.is-divider-content {
  margin-right: -0.75rem;
  padding-left: 2em;
  padding-right: 2em;
}
.steps.is-small.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}
.steps.is-small.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 0.75rem);
}
.steps.is-small.is-horizontal.has-content-centered .steps-content {
  margin-top: 1.5rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 0.2em;
}
.steps.is-small.is-horizontal.has-content-above.has-content-centered .steps-content {
  margin-bottom: 1.5rem;
  padding-bottom: 0.2em;
}
.steps.is-small.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 1.8rem;
  right: 0.3rem;
}
.steps.is-small.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-small.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.05rem);
  right: calc(-50% + 1.05rem);
}

.steps.is-medium {
  font-size: 1.25rem;
}
.steps.is-medium .steps-marker {
  height: 2.5rem;
  width: 2.5rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .steps.is-medium:not(.is-horizontal) {
    display: flex;
    flex-direction: column;
  }
  .steps.is-medium:not(.is-horizontal) .steps-segment:not(:last-child):after {
    bottom: -0.24rem;
    left: calc(1.25rem - (0.2em));
    top: 2.26rem;
    width: 0.4em;
  }
  .steps.is-medium:not(.is-horizontal) .steps-content {
    margin-left: calc(1.25rem + .5em);
    margin-top: -2.5rem;
    padding-left: 1em;
    padding-bottom: 1em;
  }
  .steps.is-medium:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }
  .steps.is-medium:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1.25rem - .5em);
  }
  .steps.is-medium:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 2.8rem;
    bottom: 0.3rem;
  }
}
.steps.is-medium.is-vertical {
  display: flex;
  flex-direction: column;
}
.steps.is-medium.is-vertical .steps-segment:not(:last-child):after {
  bottom: -0.24rem;
  left: calc(1.25rem - (0.2em));
  top: 2.26rem;
  width: 0.4em;
}
.steps.is-medium.is-vertical .steps-content {
  margin-left: calc(1.25rem + .5em);
  margin-top: -2.5rem;
  padding-left: 1em;
  padding-bottom: 1em;
}
.steps.is-medium.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}
.steps.is-medium.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1.25rem - .5em);
}
.steps.is-medium.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 2.8rem;
  bottom: 0.3rem;
}
@media screen and (min-width: 769px), print {
  .steps.is-medium:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: 0.4em;
    left: 2.26rem;
    right: -0.24rem;
    top: calc(1.25rem - (0.2em));
  }
  .steps.is-medium:not(.is-vertical).has-content-above .steps-segment:not(:last-child)::after {
    bottom: calc(1.25rem - (0.2em));
    top: auto;
  }
  .steps.is-medium:not(.is-vertical) .extra-data {
    left: calc(50% + 1.55rem);
    right: calc(-50% + 1.55rem);
    bottom: 100%;
    position: absolute;
  }
  .steps.is-medium:not(.is-vertical) .extra-data.has-overflow-right {
    overflow: visible;
    right: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.25rem - (0.2em));
  }
  .steps.is-medium:not(.is-vertical) .extra-data.has-overflow-left {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.25rem - (0.2em));
  }
  .steps.is-medium:not(.is-vertical) .extra-data.has-overflow-centered {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.25rem - (0.2em));
  }
  .steps.is-medium:not(.is-vertical).has-content-above .extra-data {
    top: 100%;
    bottom: auto;
  }
  .steps.is-medium:not(.is-vertical) .steps-content {
    margin-left: 1.25rem;
  }
  .steps.is-medium:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1.25rem;
  }
  .steps.is-medium:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1.25rem;
    padding-left: 2em;
    padding-right: 2em;
  }
  .steps.is-medium:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }
  .steps.is-medium:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1.25rem);
  }
  .steps.is-medium:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 2.5rem;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 0.2em;
  }
  .steps.is-medium:not(.is-vertical).has-content-above.has-content-centered .steps-content {
    margin-bottom: 2.5rem;
    padding-bottom: 0.2em;
  }
  .steps.is-medium:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 2.8rem;
    right: 0.3rem;
  }
  .steps.is-medium:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-medium:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.55rem);
    right: calc(-50% + 1.55rem);
  }
}
.steps.is-medium.is-horizontal .steps-segment:not(:last-child):after {
  height: 0.4em;
  left: 2.26rem;
  right: -0.24rem;
  top: calc(1.25rem - (0.2em));
}
.steps.is-medium.is-horizontal.has-content-above .steps-segment:not(:last-child)::after {
  bottom: calc(1.25rem - (0.2em));
  top: auto;
}
.steps.is-medium.is-horizontal .extra-data {
  left: calc(50% + 1.55rem);
  right: calc(-50% + 1.55rem);
  bottom: 100%;
  position: absolute;
}
.steps.is-medium.is-horizontal .extra-data.has-overflow-right {
  overflow: visible;
  right: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.25rem - (0.2em));
}
.steps.is-medium.is-horizontal .extra-data.has-overflow-left {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.25rem - (0.2em));
}
.steps.is-medium.is-horizontal .extra-data.has-overflow-centered {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.25rem - (0.2em));
}
.steps.is-medium.is-horizontal.has-content-above .extra-data {
  top: 100%;
  bottom: auto;
}
.steps.is-medium.is-horizontal .steps-content {
  margin-left: 1.25rem;
}
.steps.is-medium.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1.25rem;
}
.steps.is-medium.is-horizontal .steps-content.is-divider-content {
  margin-right: -1.25rem;
  padding-left: 2em;
  padding-right: 2em;
}
.steps.is-medium.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}
.steps.is-medium.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1.25rem);
}
.steps.is-medium.is-horizontal.has-content-centered .steps-content {
  margin-top: 2.5rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 0.2em;
}
.steps.is-medium.is-horizontal.has-content-above.has-content-centered .steps-content {
  margin-bottom: 2.5rem;
  padding-bottom: 0.2em;
}
.steps.is-medium.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 2.8rem;
  right: 0.3rem;
}
.steps.is-medium.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-medium.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.55rem);
  right: calc(-50% + 1.55rem);
}

.steps.is-large {
  font-size: 1.5rem;
}
.steps.is-large .steps-marker {
  height: 3rem;
  width: 3rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .steps.is-large:not(.is-horizontal) {
    display: flex;
    flex-direction: column;
  }
  .steps.is-large:not(.is-horizontal) .steps-segment:not(:last-child):after {
    bottom: -0.24rem;
    left: calc(1.5rem - (0.2em));
    top: 2.76rem;
    width: 0.4em;
  }
  .steps.is-large:not(.is-horizontal) .steps-content {
    margin-left: calc(1.5rem + .5em);
    margin-top: -3rem;
    padding-left: 1em;
    padding-bottom: 1em;
  }
  .steps.is-large:not(.is-horizontal) .steps-content.is-divider-content {
    margin-top: 0;
    padding-bottom: 0;
  }
  .steps.is-large:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: calc(1.5rem - .5em);
  }
  .steps.is-large:not(.is-horizontal):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-horizontal):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    top: 3.3rem;
    bottom: 0.3rem;
  }
}
.steps.is-large.is-vertical {
  display: flex;
  flex-direction: column;
}
.steps.is-large.is-vertical .steps-segment:not(:last-child):after {
  bottom: -0.24rem;
  left: calc(1.5rem - (0.2em));
  top: 2.76rem;
  width: 0.4em;
}
.steps.is-large.is-vertical .steps-content {
  margin-left: calc(1.5rem + .5em);
  margin-top: -3rem;
  padding-left: 1em;
  padding-bottom: 1em;
}
.steps.is-large.is-vertical .steps-content.is-divider-content {
  margin-top: 0;
  padding-bottom: 0;
}
.steps.is-large.is-vertical.has-content-centered .steps-content {
  padding-top: calc(1.5rem - .5em);
}
.steps.is-large.is-vertical:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-vertical:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  top: 3.3rem;
  bottom: 0.3rem;
}
@media screen and (min-width: 769px), print {
  .steps.is-large:not(.is-vertical) .steps-segment:not(:last-child):after {
    height: 0.4em;
    left: 2.76rem;
    right: -0.24rem;
    top: calc(1.5rem - (0.2em));
  }
  .steps.is-large:not(.is-vertical).has-content-above .steps-segment:not(:last-child)::after {
    bottom: calc(1.5rem - (0.2em));
    top: auto;
  }
  .steps.is-large:not(.is-vertical) .extra-data {
    left: calc(50% + 1.8rem);
    right: calc(-50% + 1.8rem);
    bottom: 100%;
    position: absolute;
  }
  .steps.is-large:not(.is-vertical) .extra-data.has-overflow-right {
    overflow: visible;
    right: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.5rem - (0.2em));
  }
  .steps.is-large:not(.is-vertical) .extra-data.has-overflow-left {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.5rem - (0.2em));
  }
  .steps.is-large:not(.is-vertical) .extra-data.has-overflow-centered {
    overflow: visible;
    left: auto;
    white-space: nowrap;
    min-width: calc(100% - 1.5rem - (0.2em));
  }
  .steps.is-large:not(.is-vertical).has-content-above .extra-data {
    top: 100%;
    bottom: auto;
  }
  .steps.is-large:not(.is-vertical) .steps-content {
    margin-left: 1.5rem;
  }
  .steps.is-large:not(.is-vertical) .steps-content:not(:last-child) {
    margin-right: -1.5rem;
  }
  .steps.is-large:not(.is-vertical) .steps-content.is-divider-content {
    margin-right: -1.5rem;
    padding-left: 2em;
    padding-right: 2em;
  }
  .steps.is-large:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
  }
  .steps.is-large:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 1.5rem);
  }
  .steps.is-large:not(.is-vertical).has-content-centered .steps-content {
    margin-top: 3rem;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 0.2em;
  }
  .steps.is-large:not(.is-vertical).has-content-above.has-content-centered .steps-content {
    margin-bottom: 3rem;
    padding-bottom: 0.2em;
  }
  .steps.is-large:not(.is-vertical):not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-vertical):not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
    left: 3.3rem;
    right: 0.3rem;
  }
  .steps.is-large:not(.is-vertical):not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-large:not(.is-vertical):not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.8rem);
    right: calc(-50% + 1.8rem);
  }
}
.steps.is-large.is-horizontal .steps-segment:not(:last-child):after {
  height: 0.4em;
  left: 2.76rem;
  right: -0.24rem;
  top: calc(1.5rem - (0.2em));
}
.steps.is-large.is-horizontal.has-content-above .steps-segment:not(:last-child)::after {
  bottom: calc(1.5rem - (0.2em));
  top: auto;
}
.steps.is-large.is-horizontal .extra-data {
  left: calc(50% + 1.8rem);
  right: calc(-50% + 1.8rem);
  bottom: 100%;
  position: absolute;
}
.steps.is-large.is-horizontal .extra-data.has-overflow-right {
  overflow: visible;
  right: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.5rem - (0.2em));
}
.steps.is-large.is-horizontal .extra-data.has-overflow-left {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.5rem - (0.2em));
}
.steps.is-large.is-horizontal .extra-data.has-overflow-centered {
  overflow: visible;
  left: auto;
  white-space: nowrap;
  min-width: calc(100% - 1.5rem - (0.2em));
}
.steps.is-large.is-horizontal.has-content-above .extra-data {
  top: 100%;
  bottom: auto;
}
.steps.is-large.is-horizontal .steps-content {
  margin-left: 1.5rem;
}
.steps.is-large.is-horizontal .steps-content:not(:last-child) {
  margin-right: -1.5rem;
}
.steps.is-large.is-horizontal .steps-content.is-divider-content {
  margin-right: -1.5rem;
  padding-left: 2em;
  padding-right: 2em;
}
.steps.is-large.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: 50%;
  right: -50%;
}
.steps.is-large.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 1.5rem);
}
.steps.is-large.is-horizontal.has-content-centered .steps-content {
  margin-top: 3rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 0.2em;
}
.steps.is-large.is-horizontal.has-content-above.has-content-centered .steps-content {
  margin-bottom: 3rem;
  padding-bottom: 0.2em;
}
.steps.is-large.is-horizontal:not(.is-thin).has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-horizontal:not(.is-thin) .steps-segment.has-gaps:not(:last-child):after {
  left: 3.3rem;
  right: 0.3rem;
}
.steps.is-large.is-horizontal:not(.is-thin).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-large.is-horizontal:not(.is-thin).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 1.8rem);
  right: calc(-50% + 1.8rem);
}

.steps-segment:after {
  background-color: hsl(141deg, 71%, 48%);
}
.steps-segment.is-active:after {
  background-color: hsl(0deg, 0%, 86%);
}
.steps-segment.is-active ~ .steps-segment:after {
  background-color: hsl(0deg, 0%, 86%);
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow) {
  background-color: hsl(141deg, 71%, 48%);
  color: #fff;
}
.steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: hsl(141deg, 71%, 48%);
  color: #fff;
}
.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
  background-color: hsl(0deg, 0%, 86%);
  color: rgba(0, 0, 0, 0.7);
}

.steps.is-hollow .steps-marker,
.steps-marker.is-hollow {
  border: 0.3em solid hsl(141deg, 71%, 48%);
}

.steps.is-hollow .is-active .steps-marker,
.steps .is-active .steps-marker.is-hollow {
  border-color: hsl(141deg, 71%, 48%);
}

.steps.is-hollow .steps-segment.is-active ~ .steps-segment .steps-marker,
.steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow {
  border-color: hsl(0deg, 0%, 86%);
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-white {
  background-color: hsl(0deg, 0%, 100%) !important;
  color: hsl(0deg, 0%, 4%) !important;
}

.steps.is-hollow .steps-marker.is-white,
.steps .steps-marker.is-hollow.is-white {
  border-color: hsl(0deg, 0%, 100%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-black {
  background-color: hsl(0deg, 0%, 4%) !important;
  color: hsl(0deg, 0%, 100%) !important;
}

.steps.is-hollow .steps-marker.is-black,
.steps .steps-marker.is-hollow.is-black {
  border-color: hsl(0deg, 0%, 4%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-light {
  background-color: hsl(0deg, 0%, 96%) !important;
  color: hsl(0deg, 0%, 21%) !important;
}

.steps.is-hollow .steps-marker.is-light,
.steps .steps-marker.is-hollow.is-light {
  border-color: hsl(0deg, 0%, 96%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-dark {
  background-color: hsl(0deg, 0%, 21%) !important;
  color: hsl(0deg, 0%, 96%) !important;
}

.steps.is-hollow .steps-marker.is-dark,
.steps .steps-marker.is-hollow.is-dark {
  border-color: hsl(0deg, 0%, 21%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-primary {
  background-color: hsl(171deg, 100%, 41%) !important;
  color: #fff !important;
}

.steps.is-hollow .steps-marker.is-primary,
.steps .steps-marker.is-hollow.is-primary {
  border-color: hsl(171deg, 100%, 41%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-link {
  background-color: hsl(217deg, 71%, 53%) !important;
  color: #fff !important;
}

.steps.is-hollow .steps-marker.is-link,
.steps .steps-marker.is-hollow.is-link {
  border-color: hsl(217deg, 71%, 53%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-info {
  background-color: hsl(204deg, 86%, 53%) !important;
  color: #fff !important;
}

.steps.is-hollow .steps-marker.is-info,
.steps .steps-marker.is-hollow.is-info {
  border-color: hsl(204deg, 86%, 53%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-success {
  background-color: hsl(141deg, 71%, 48%) !important;
  color: #fff !important;
}

.steps.is-hollow .steps-marker.is-success,
.steps .steps-marker.is-hollow.is-success {
  border-color: hsl(141deg, 71%, 48%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-warning {
  background-color: hsl(48deg, 100%, 67%) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.steps.is-hollow .steps-marker.is-warning,
.steps .steps-marker.is-hollow.is-warning {
  border-color: hsl(48deg, 100%, 67%) !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow).is-danger {
  background-color: hsl(348deg, 100%, 61%) !important;
  color: #fff !important;
}

.steps.is-hollow .steps-marker.is-danger,
.steps .steps-marker.is-hollow.is-danger {
  border-color: hsl(348deg, 100%, 61%) !important;
}

@media screen and (max-width: 768px) {
  .steps:not(.is-horizontal).is-dashed .steps-segment:after, .steps:not(.is-horizontal) .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(0deg, hsl(141deg, 71%, 48%), hsl(141deg, 71%, 48%) 5px, transparent 5px, transparent 10px);
  }
  .steps:not(.is-horizontal).is-dashed .steps-segment.is-active:after, .steps:not(.is-horizontal).is-dashed .steps-segment.is-active ~ .steps-segment:after,
.steps:not(.is-horizontal) .steps-segment.is-active.is-dashed:after,
.steps:not(.is-horizontal) .steps-segment.is-active ~ .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(0deg, hsl(0deg, 0%, 86%), hsl(0deg, 0%, 86%) 5px, transparent 5px, transparent 10px);
  }
}
.steps.is-vertical.is-dashed .steps-segment:after, .steps.is-vertical .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(0deg, hsl(141deg, 71%, 48%), hsl(141deg, 71%, 48%) 5px, transparent 5px, transparent 10px);
}
.steps.is-vertical.is-dashed .steps-segment.is-active:after, .steps.is-vertical.is-dashed .steps-segment.is-active ~ .steps-segment:after,
.steps.is-vertical .steps-segment.is-active.is-dashed:after,
.steps.is-vertical .steps-segment.is-active ~ .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(0deg, hsl(0deg, 0%, 86%), hsl(0deg, 0%, 86%) 5px, transparent 5px, transparent 10px);
}
@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical).is-dashed .steps-segment:after,
.steps:not(.is-vertical) .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(90deg, hsl(141deg, 71%, 48%), hsl(141deg, 71%, 48%) 10px, transparent 10px, transparent 20px);
  }
  .steps:not(.is-vertical).is-dashed .steps-segment.is-active:after, .steps:not(.is-vertical).is-dashed .steps-segment.is-active ~ .steps-segment:after,
.steps:not(.is-vertical) .steps-segment.is-active.is-dashed:after,
.steps:not(.is-vertical) .steps-segment.is-active ~ .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(90deg, hsl(0deg, 0%, 86%), hsl(0deg, 0%, 86%) 10px, transparent 10px, transparent 20px);
  }
}
.steps.is-horizontal.is-dashed .steps-segment:after,
.steps.is-horizontal .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(90deg, hsl(141deg, 71%, 48%), hsl(141deg, 71%, 48%) 10px, transparent 10px, transparent 20px);
}
.steps.is-horizontal.is-dashed .steps-segment.is-active:after, .steps.is-horizontal.is-dashed .steps-segment.is-active ~ .steps-segment:after,
.steps.is-horizontal .steps-segment.is-active.is-dashed:after,
.steps.is-horizontal .steps-segment.is-active ~ .steps-segment.is-dashed:after {
  background: repeating-linear-gradient(90deg, hsl(0deg, 0%, 86%), hsl(0deg, 0%, 86%) 10px, transparent 10px, transparent 20px);
}

.steps.is-thin .steps-marker {
  width: 0.8em;
  height: 0.8em;
}
.steps.is-thin.is-hollow .steps-marker,
.steps.is-thin .steps-marker.is-hollow {
  border-width: 1px;
  height: calc(0.8em + 1px);
  width: calc(0.8em + 1px);
}
@media screen and (max-width: 768px) {
  .steps.is-thin:not(.is-horizontal) .steps-segment:not(:last-child):after {
    bottom: 0;
    left: calc(0.4em - 0.5px);
    top: 0.8em;
    width: 1px;
  }
  .steps.is-thin:not(.is-horizontal) .steps-content {
    margin-top: -1.2em;
    margin-left: 0.8em;
  }
  .steps.is-thin:not(.is-horizontal).has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-horizontal) .steps-segment.has-gaps:not(:last-child):after {
    bottom: 0.4em;
    top: 1.2em;
  }
  .steps.is-thin:not(.is-horizontal).has-content-centered .steps-content {
    padding-top: 1.6em;
  }
}
.steps.is-thin.is-vertical .steps-segment:not(:last-child):after {
  bottom: 0;
  left: calc(0.4em - 0.5px);
  top: 0.8em;
  width: 1px;
}
.steps.is-thin.is-vertical .steps-content {
  margin-top: -1.2em;
  margin-left: 0.8em;
}
.steps.is-thin.is-vertical.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-vertical .steps-segment.has-gaps:not(:last-child):after {
  bottom: 0.4em;
  top: 1.2em;
}
.steps.is-thin.is-vertical.has-content-centered .steps-content {
  padding-top: 1.6em;
}
@media screen and (min-width: 769px), print {
  .steps.is-thin:not(.is-vertical) .steps-segment:not(:last-child):after {
    left: 0.8em;
    right: 0;
    top: calc(0.4em - 0.5px);
    height: 1px;
  }
  .steps.is-thin:not(.is-vertical).has-content-above .steps-segment:not(:last-child)::after {
    bottom: calc(0.4em - 0.5px);
    top: auto;
  }
  .steps.is-thin:not(.is-vertical) .steps-content {
    margin-top: 0.8em;
  }
  .steps.is-thin:not(.is-vertical).has-content-centered .steps-segment:not(:last-child):after {
    left: calc(50% + 0.4em);
    right: calc(-50% + 0.4em);
  }
  .steps.is-thin:not(.is-vertical).has-content-centered .steps-marker {
    position: absolute;
    left: calc(50% - 0.4em);
  }
  .steps.is-thin:not(.is-vertical).has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-vertical) .steps-segment.has-gaps:not(:last-child):after {
    left: 1.2em;
    right: 0.4em;
  }
  .steps.is-thin:not(.is-vertical).has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin:not(.is-vertical).has-content-centered .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 0.8em);
    right: calc(-50% + 0.8em);
  }
}
.steps.is-thin.is-horizontal .steps-segment:not(:last-child):after {
  left: 0.8em;
  right: 0;
  top: calc(0.4em - 0.5px);
  height: 1px;
}
.steps.is-thin.is-horizontal.has-content-above .steps-segment:not(:last-child)::after {
  bottom: calc(0.4em - 0.5px);
  top: auto;
}
.steps.is-thin.is-horizontal .steps-content {
  margin-top: 0.8em;
}
.steps.is-thin.is-horizontal.has-content-centered .steps-segment:not(:last-child):after {
  left: calc(50% + 0.4em);
  right: calc(-50% + 0.4em);
}
.steps.is-thin.is-horizontal.has-content-centered .steps-marker {
  position: absolute;
  left: calc(50% - 0.4em);
}
.steps.is-thin.is-horizontal.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-horizontal .steps-segment.has-gaps:not(:last-child):after {
  left: 1.2em;
  right: 0.4em;
}
.steps.is-thin.is-horizontal.has-content-centered.has-gaps .steps-segment:not(:last-child):after, .steps.is-thin.is-horizontal.has-content-centered .steps-segment.has-gaps:not(:last-child):after {
  left: calc(50% + 0.8em);
  right: calc(-50% + 0.8em);
}

.steps:not(.is-vertical).has-content-above .steps-segment {
  display: flex;
  flex-direction: column-reverse;
}
.steps:not(.is-vertical).has-content-above .steps-segment .steps-content {
  margin-top: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column-reverse;
}

html, body {
  font-family: "Poppins", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

trix-editor {
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 5em;
  outline: none;
}

trix-toolbar * {
  box-sizing: border-box;
}

trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
}

trix-toolbar .trix-button-group {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px;
}

trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 1.5vw;
}

@media (max-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0;
  }
}
trix-toolbar .trix-button-group-spacer {
  flex-grow: 1;
}

@media (max-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: none;
  }
}
trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: transparent;
}

trix-toolbar .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-toolbar .trix-button.trix-active {
  background: #cbeefa;
  color: black;
}

trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125);
}

@media (max-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    padding: 0 0.3em;
  }
}
trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px;
}

@media (max-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw);
  }
}
trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%;
  }
}
trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1;
}

trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125;
}

trix-toolbar .trix-button--icon-attach::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.5%2018V7.5c0-2.25%203-2.25%203%200V18c0%204.125-6%204.125-6%200V7.5c0-6.375%209-6.375%209%200V18%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  top: 8%;
  bottom: 4%;
}

trix-toolbar .trix-button--icon-bold::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6.522%2019.242a.5.5%200%200%201-.5-.5V5.35a.5.5%200%200%201%20.5-.5h5.783c1.347%200%202.46.345%203.24.982.783.64%201.216%201.562%201.216%202.683%200%201.13-.587%202.129-1.476%202.71a.35.35%200%200%200%20.049.613c1.259.56%202.101%201.742%202.101%203.22%200%201.282-.483%202.334-1.363%203.063-.876.726-2.132%201.12-3.66%201.12h-5.89ZM9.27%207.347v3.362h1.97c.766%200%201.347-.17%201.733-.464.38-.291.587-.716.587-1.27%200-.53-.183-.928-.513-1.198-.334-.273-.838-.43-1.505-.43H9.27Zm0%205.606v3.791h2.389c.832%200%201.448-.177%201.853-.497.399-.315.614-.786.614-1.423%200-.62-.22-1.077-.63-1.385-.418-.313-1.053-.486-1.905-.486H9.27Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-italic::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9%205h6.5v2h-2.23l-2.31%2010H13v2H6v-2h2.461l2.306-10H9V5Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-link::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M18.948%205.258a4.337%204.337%200%200%200-6.108%200L11.217%206.87a.993.993%200%200%200%200%201.41c.392.39%201.027.39%201.418%200l1.623-1.613a2.323%202.323%200%200%201%203.271%200%202.29%202.29%200%200%201%200%203.251l-2.393%202.38a3.021%203.021%200%200%201-4.255%200l-.05-.049a1.007%201.007%200%200%200-1.418%200%20.993.993%200%200%200%200%201.41l.05.049a5.036%205.036%200%200%200%207.091%200l2.394-2.38a4.275%204.275%200%200%200%200-6.072Zm-13.683%2013.6a4.337%204.337%200%200%200%206.108%200l1.262-1.255a.993.993%200%200%200%200-1.41%201.007%201.007%200%200%200-1.418%200L9.954%2017.45a2.323%202.323%200%200%201-3.27%200%202.29%202.29%200%200%201%200-3.251l2.344-2.331a2.579%202.579%200%200%201%203.631%200c.392.39%201.027.39%201.419%200a.993.993%200%200%200%200-1.41%204.593%204.593%200%200%200-6.468%200l-2.345%202.33a4.275%204.275%200%200%200%200%206.072Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-strike::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6%2014.986c.088%202.647%202.246%204.258%205.635%204.258%203.496%200%205.713-1.728%205.713-4.463%200-.275-.02-.536-.062-.781h-3.461c.398.293.573.654.573%201.123%200%201.035-1.074%201.787-2.646%201.787-1.563%200-2.773-.762-2.91-1.924H6ZM6.432%2010h3.763c-.632-.314-.914-.715-.914-1.273%200-1.045.977-1.739%202.432-1.739%201.475%200%202.52.723%202.617%201.914h2.764c-.05-2.548-2.11-4.238-5.39-4.238-3.145%200-5.392%201.719-5.392%204.316%200%20.363.04.703.12%201.02ZM4%2011a1%201%200%201%200%200%202h15a1%201%200%201%200%200-2H4Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-quote::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M4.581%208.471c.44-.5%201.056-.834%201.758-.995C8.074%207.17%209.201%207.822%2010%208.752c1.354%201.578%201.33%203.555.394%205.277-.941%201.731-2.788%203.163-4.988%203.56a.622.622%200%200%201-.653-.317c-.113-.205-.121-.49.16-.764.294-.286.567-.566.791-.835.222-.266.413-.54.524-.815.113-.28.156-.597.026-.908-.128-.303-.39-.524-.72-.69a3.02%203.02%200%200%201-1.674-2.7c0-.905.283-1.59.72-2.088Zm9.419%200c.44-.5%201.055-.834%201.758-.995%201.734-.306%202.862.346%203.66%201.276%201.355%201.578%201.33%203.555.395%205.277-.941%201.731-2.789%203.163-4.988%203.56a.622.622%200%200%201-.653-.317c-.113-.205-.122-.49.16-.764.294-.286.567-.566.791-.835.222-.266.412-.54.523-.815.114-.28.157-.597.026-.908-.127-.303-.39-.524-.72-.69a3.02%203.02%200%200%201-1.672-2.701c0-.905.283-1.59.72-2.088Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M21.5%207.5v-3h-12v3H14v13h3v-13h4.5ZM9%2013.5h3.5v-3h-10v3H6v7h3v-7Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-code::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.293%2011.293a1%201%200%200%200%200%201.414l4%204a1%201%200%201%200%201.414-1.414L5.414%2012l3.293-3.293a1%201%200%200%200-1.414-1.414l-4%204Zm13.414%205.414%204-4a1%201%200%200%200%200-1.414l-4-4a1%201%200%201%200-1.414%201.414L18.586%2012l-3.293%203.293a1%201%200%200%200%201.414%201.414Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5%207.5a1.5%201.5%200%201%200%200-3%201.5%201.5%200%200%200%200%203ZM8%206a1%201%200%200%201%201-1h11a1%201%200%201%201%200%202H9a1%201%200%200%201-1-1Zm1%205a1%201%200%201%200%200%202h11a1%201%200%201%200%200-2H9Zm0%206a1%201%200%201%200%200%202h11a1%201%200%201%200%200-2H9Zm-2.5-5a1.5%201.5%200%201%201-3%200%201.5%201.5%200%200%201%203%200ZM5%2019.5a1.5%201.5%200%201%200%200-3%201.5%201.5%200%200%200%200%203Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-number-list::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3%204h2v4H4V5H3V4Zm5%202a1%201%200%200%201%201-1h11a1%201%200%201%201%200%202H9a1%201%200%200%201-1-1Zm1%205a1%201%200%201%200%200%202h11a1%201%200%201%200%200-2H9Zm0%206a1%201%200%201%200%200%202h11a1%201%200%201%200%200-2H9Zm-3.5-7H6v1l-1.5%202H6v1H3v-1l1.667-2H3v-1h2.5ZM3%2017v-1h3v4H3v-1h2v-.5H4v-1h1V17H3Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-undo::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3%2014a1%201%200%200%200%201%201h6a1%201%200%201%200%200-2H6.257c2.247-2.764%205.151-3.668%207.579-3.264%202.589.432%204.739%202.356%205.174%205.405a1%201%200%200%200%201.98-.283c-.564-3.95-3.415-6.526-6.825-7.095C11.084%207.25%207.63%208.377%205%2011.39V8a1%201%200%200%200-2%200v6Zm2-1Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-redo::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M21%2014a1%201%200%200%201-1%201h-6a1%201%200%201%201%200-2h3.743c-2.247-2.764-5.151-3.668-7.579-3.264-2.589.432-4.739%202.356-5.174%205.405a1%201%200%200%201-1.98-.283c.564-3.95%203.415-6.526%206.826-7.095%203.08-.513%206.534.614%209.164%203.626V8a1%201%200%201%201%202%200v6Zm-2-1Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5%206a1%201%200%200%201%201-1h12a1%201%200%201%201%200%202H6a1%201%200%200%201-1-1Zm4%205a1%201%200%201%200%200%202h9a1%201%200%201%200%200-2H9Zm-3%206a1%201%200%201%200%200%202h12a1%201%200%201%200%200-2H6Zm-3.707-5.707a1%201%200%200%200%200%201.414l2%202a1%201%200%201%200%201.414-1.414L4.414%2012l1.293-1.293a1%201%200%200%200-1.414-1.414l-2%202Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5%206a1%201%200%200%201%201-1h12a1%201%200%201%201%200%202H6a1%201%200%200%201-1-1Zm4%205a1%201%200%201%200%200%202h9a1%201%200%201%200%200-2H9Zm-3%206a1%201%200%201%200%200%202h12a1%201%200%201%200%200-2H6Zm-2.293-2.293%202-2a1%201%200%200%200%200-1.414l-2-2a1%201%200%201%200-1.414%201.414L3.586%2012l-1.293%201.293a1%201%200%201%200%201.414%201.414Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
}

trix-toolbar .trix-dialogs {
  position: relative;
}

trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5;
}

trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #F00 0px 0px 1.5px 1px;
}

trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none;
}

trix-toolbar .trix-dialog--link {
  max-width: 600px;
}

trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline;
}

trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1;
}

trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0;
}

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection, trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection, trix-editor [data-trix-mutable] ::selection {
  background: none;
}

trix-editor .attachment__caption-editor:focus[data-trix-mutable]::-moz-selection {
  background: highlight;
}

trix-editor .attachment__caption-editor:focus[data-trix-mutable]::selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}

trix-editor .attachment {
  position: relative;
}

trix-editor .attachment:hover {
  cursor: default;
}

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}

trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}

trix-editor .trix-button-group {
  display: inline-flex;
}

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}

trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-editor .trix-button.trix-active {
  background: #cbeefa;
}

trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}

trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.41%2017.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}

trix-editor .trix-button--remove:hover {
  border-color: #333;
}

trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}

trix-editor .attachment__metadata-container {
  position: relative;
}

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}

trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}

.trix-content {
  line-height: 1.5;
}

.trix-content * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.2;
}

.trix-content blockquote {
  border: 0 solid #ccc;
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em;
}

.trix-content [dir=rtl] blockquote,
.trix-content blockquote[dir=rtl] {
  border-width: 0;
  border-right-width: 0.3em;
  margin-right: 0.3em;
  padding-right: 0.6em;
}

.trix-content li {
  margin-left: 1em;
}

.trix-content [dir=rtl] li {
  margin-right: 1em;
}

.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}

.trix-content img {
  max-width: 100%;
  height: auto;
}

.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.trix-content .attachment a {
  color: inherit;
  text-decoration: none;
}

.trix-content .attachment a:hover, .trix-content .attachment a:visited:hover {
  color: inherit;
}

.trix-content .attachment__caption {
  text-align: center;
}

.trix-content .attachment__caption .attachment__name + .attachment__size::before {
  content: " •";
}

.trix-content .attachment--preview {
  width: 100%;
  text-align: center;
}

.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2;
}

.trix-content .attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 2px;
  padding: 0.4em 1em;
  border: 1px solid #bbb;
  border-radius: 5px;
}

.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 .attachment, .trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}
.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment, .trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}
.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}
.trix-content .attachment[data-trix-content-type="application/vnd.trix.horizontal-rule.html"] {
  display: block;
}
.trix-content h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.trix-content h2 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
}
.trix-content h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}
.trix-content ul {
  list-style: inherit;
}
.trix-content ul li {
  margin-left: 15px;
}

.fade-in {
  animation: fadeIn ease 2s forwards;
  -webkit-animation: fadeIn ease 2s forwards;
  -moz-animation: fadeIn ease 2s forwards;
  -o-animation: fadeIn ease 2s forwards;
  -ms-animation: fadeIn ease 2s forwards;
}

.fade-out {
  animation: fadeOut ease 2s forwards;
  -webkit-animation: fadeOut ease 2s forwards;
  -moz-animation: fadeOut ease 2s forwards;
  -o-animation: fadeOut ease 2s forwards;
  -ms-animation: fadeOut ease 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.has-text-primary-light {
  color: #c8eb67;
}

.has-text-primary-dark {
  color: #8fa849;
}

.has-text-secondary-light {
  color: #cfcfcf;
}

.has-text-secondary-dark {
  color: #707070;
}

.has-text-nowrap {
  white-space: nowrap;
}

.has-text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.has-background-primary-light {
  background-color: #c8eb67;
}

.has-background-primary-dark {
  background-color: #8fa849;
}

.has-background-secondary-light {
  background-color: #cfcfcf;
}

.has-background-secondary-dark {
  background-color: #707070;
  color: white !important;
}

.has-background-primary-light-25 {
  background-color: rgba(0, 160, 161, 0.1450980392);
}

.has-text-vertical-middle {
  vertical-align: middle !important;
}

.no-selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

strong {
  color: inherit;
}

.opacity-0 {
  opacity: 0 !important;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 99vw;
  display: none;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 900;
}

.overlay.active {
  display: block !important;
}

.modal.checkout {
  position: fixed;
}

div.checkout-twint-logo {
  width: 1.8em;
}
div.checkout-twint-logo img.checkout-twint-logo {
  height: 1.7rem;
}

div.checkout-postfinance-logo {
  width: 1.7em;
}
div.checkout-postfinance-logo img.checkout-postfinance-logo {
  height: 1.5rem;
}

article.item-checkout div.media-content {
  display: flex;
  justify-content: space-between;
}
article.item-checkout div.media-content div.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  height: 100%;
}
article.item-checkout div.media-content div.content div.details {
  flex-grow: 1;
}
article.item-checkout div.media-content div.qts-total {
  height: 100%;
}
article.item-checkout div.media-content div.qts-total div:first-child {
  width: 100%;
}
article.item-checkout div.media-content div.qts-total .price-total {
  width: 200px;
  white-space: nowrap;
}
article.item-checkout button.remove {
  font-size: 0.65rem;
}
article.item-checkout button.remove svg {
  margin-top: -2px;
}

@media screen and (max-width: 768px) {
  article.item-checkout .price-total {
    font-size: 0.9rem !important;
    padding-top: 5px;
    width: auto !important;
  }
  article.item-checkout div.media-content {
    flex-direction: column;
  }
  article.item-checkout div.media-content div.content {
    margin-bottom: 0.25em;
  }
}
.modal {
  position: fixed !important;
}

.modal-card-head {
  padding: 10px;
}

.modal-card-foot {
  justify-content: flex-end;
  padding: 10px;
}

.hero.header {
  transition: background-image 2s;
  position: relative;
}
.hero.header .hero-body {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.hero.header .header-logo {
  max-width: 50vw;
  max-height: calc(100vh - 45rem);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.hero.header .subtitle {
  text-shadow: 0 1px 2px #ccc;
}
.hero.header .product, .hero.header .category {
  min-height: 40vh;
}

.hero.header.start {
  height: calc(100vh - 240px);
}
.hero.header.start .hero-body {
  padding-top: unset;
  padding-bottom: unset;
}
.hero.header.start .hero-body div.container {
  height: 100%;
}
.hero.header.start video {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  max-width: unset !important;
  min-width: 100%;
}

.hero.start-gallery .columns {
  margin: -0.5rem 0 -0.5rem 0 !important;
}
.hero.start-gallery .columns .column {
  padding: 0.5rem !important;
}
.hero.start-gallery .columns .column:first-child {
  padding: 0.5rem 0.5rem 0.5rem 0 !important;
}
.hero.start-gallery .columns .column:last-child {
  padding: 0.5rem 0 0.5rem 0.5rem !important;
}

.hero.start-list .columns {
  margin: -0.5rem 0 -0.5rem 0 !important;
}

.hero.header.static {
  background-image: url("/images/start_header/start_header.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.hero-carousel .slider .slider-container .slider-item {
  min-width: 100vw !important;
  height: 100%;
}
.hero-carousel .slider .slider-container .slider-item img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.hero-carousel .slider .slider-navigation-previous, .hero-carousel .slider .slider-navigation-next, .hero-carousel .slider .slider-pagination {
  display: none;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: #8fa849 !important;
}

@media screen and (min-width: 1921px) {
  .hero.header .product, .hero.header .category {
    min-height: 50vh;
  }
}
.hero.start-footer {
  background: url("../images/start_footer.webp"), url("../images/start_footer_default.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0px;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.hero.start-footer .container {
  text-shadow: 0 1px 2px #000;
}

section.hero.static {
  background: url("../images/static-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0px;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
section.hero.static .container {
  text-shadow: 0 1px 2px #000;
}

.smaller {
  font-size: 0.9rem;
}

#dropdown-menu-cart.dropdown-menu {
  min-width: 20rem;
}
#dropdown-menu-cart.dropdown-menu .dropdown-content button {
  width: 100%;
}
#dropdown-menu-cart.dropdown-menu .dropdown-content p.image {
  padding: 2px;
}
#dropdown-menu-cart.dropdown-menu .dropdown-content p.image img {
  height: auto;
  width: auto;
  max-height: unset;
  margin: auto;
}

.cart-item-counter {
  font-size: 0.6rem !important;
  border-radius: 12px !important;
}

.nav-categories {
  z-index: 6;
}

.navbar.cookies {
  height: auto;
}

.cart-notification {
  position: fixed;
  bottom: 10vh;
  z-index: 1000;
  width: 80%;
  margin-left: 10%;
}

div.cart-menu-wrapper {
  position: fixed;
  top: 0;
  right: -40rem;
  width: 30rem;
  max-width: 80vw;
  height: 100vh;
  padding-top: 52px;
  z-index: 1000;
  background-color: white;
  padding: 1rem;
  overflow-y: auto;
  box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.35);
  transition: all 0.3s;
}

div.cart-menu-wrapper.active {
  right: 0;
}

.navbar {
  box-shadow: 0 0 0.125em 0 rgba(0, 0, 0, 0.35);
  height: 52px;
}

/*
.navbar-dropdown {
  max-height: 50vh;
}
*/
/* Navbar Megamenu */
.navbar-item.is-mega {
  position: static;
}
.navbar-item.is-mega .is-mega-menu-title {
  margin-bottom: 0;
  padding: 0.375rem 1rem;
}

.search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B5B5B5;
  opacity: 1; /* Firefox */
}

.search:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B5B5B5;
}

.search::-ms-input-placeholder { /* Microsoft Edge */
  color: #B5B5B5;
}

.dropdown-content {
  overflow-y: auto;
  max-height: inherit;
}
.dropdown-content #header_cart_items {
  max-height: 80%;
}

.m-auto {
  margin: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.button {
  /* Ignore click on icon */
}
.button i.fas, .button i.far {
  pointer-events: none;
}
.button svg {
  pointer-events: none;
}

.is-clickable {
  cursor: pointer;
}

.is-clickable-not {
  pointer-events: none;
}

.strikethrough {
  text-decoration: line-through;
}

/*
  Categories
*/
.box.category img {
  border-radius: 6px;
}
.box.category div.is-overlay {
  display: flex;
  flex-direction: column-reverse;
}
.box.category div.is-overlay div.name {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 3rem;
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  line-height: calc(3rem - 50%);
}

.card.category .card-image figure {
  margin: auto;
}
.card.category .card-image figure img {
  height: auto;
  width: auto;
  min-width: 100%;
}

.category.description-long figure {
  text-align: center;
}

/*
  Products
*/
/*
  Content
*/
.card {
  border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0;
}

div.card.product, div.card.category {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.card.product .card-image figure, div.card.category .card-image figure {
  margin: auto;
}
div.card.product .card-image figure img, div.card.category .card-image figure img {
  height: auto;
  width: auto;
  min-width: 100%;
}
div.card.product a, div.card.product a:visited, div.card.category a, div.card.category a:visited {
  color: inherit;
}
div.card.product .description, div.card.category .description {
  font-size: 0.9rem;
}

div.product.image {
  width: 100%;
  height: auto;
  background-color: white;
  border: 1px solid hsl(171deg, 100%, 41%);
  padding: 3px;
}
div.product.image figure {
  margin: auto;
  width: 100%;
}

div.hero-body.product .description {
  font-size: 0.9rem;
}
div.hero-body.product .description ul {
  list-style: inherit;
}
div.hero-body.product .description ul li {
  margin-left: 15px;
}
div.hero-body.product div.tabs {
  height: 42px;
}
div.hero-body.product div.tabs ul {
  border-bottom-color: hsl(0deg, 0%, 86%);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
div.hero-body.product .images {
  color: #8fa849;
}

figure.image.zoom, img.zoom {
  transition: transform 0.4s;
}

figure.image.zoom:hover, img.zoom:hover {
  transform: scale(1.1);
}

figure.image.product span.action {
  position: absolute;
  top: calc(50% - 24px);
  color: #aaa;
  font-size: 0.7rem;
}
figure.image.product span.action.left {
  left: "5px";
}
figure.image.product span.action.zoom {
  right: calc(50% - 32px);
}
figure.image.product span.action.right {
  right: 0;
}
figure.image.product .fa-circle {
  color: #8fa849;
}
figure.image.product svg {
  pointer-events: none;
}

figure.video.product {
  display: inline-block;
  position: relative;
}
figure.video.product svg {
  position: absolute;
  top: calc(50% - 16px);
  right: calc(50% - 16px);
  color: #aaa;
  pointer-events: none;
}

div.product.images {
  max-width: 400px;
  margin-top: 0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
div.product.images div {
  width: 125px;
  height: 125px;
  border: 1px solid #cfcfcf;
  padding: 1px;
  background-color: white;
  margin: 0.25rem;
}
div.product.images div img {
  margin: auto;
}

.product-picture-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-picture-wrapper img {
  text-align: center;
  margin: auto;
  opacity: 1;
  max-width: 80vw;
  max-height: calc(80vh - 80px);
}
.product-picture-wrapper .thumbnails img {
  text-align: center;
  margin: auto;
  opacity: 1;
  border: 1px solid white;
  padding: 1px;
  max-width: 8vw;
  max-height: 8vh;
}
.product-picture-wrapper .thumbnails img.active {
  border-color: hsl(171deg, 100%, 41%);
}

.product-video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  cursor: hand;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-video-wrapper video {
  text-align: center;
  margin: auto;
  opacity: 1;
  border: 1px solid white;
  padding: 1px;
  max-width: 80%;
  max-height: 80%;
}

table.product-details {
  background-color: transparent;
}

div.product div.price p.price {
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  div.product div.price div:first-child {
    flex-direction: column;
  }
  div.product div.price div:first-child div.add_to_cart, div.product div.price div:first-child p.price {
    align-self: flex-start;
  }
  div.product div.price div:first-child div.add_to_cart div, div.product div.price div:first-child p.price div {
    flex-direction: row;
  }
  div.product div.price div.bulk_price {
    font-size: 95% !important;
  }
}

/*
  Footer
*/
.footer {
  padding: 4rem 1.5rem 2rem;
}

footer strong {
  color: inherit;
}

footer .menu-list a {
  color: hsl(0deg, 0%, 98%);
  font-size: 0.75rem;
}

footer .menu-list a:hover {
  background-color: inherit;
  color: inherit;
}

@media screen and (min-width: 1216px) {
  .start.tile .tile.is-parent {
    max-width: 50%;
  }
}
@media screen and (min-width: 1408px) {
  .start.tile .tile.is-parent {
    max-width: 33%;
  }
}
/* Customization */
.button.is-primary {
  color: white !important;
  background-color: #8fa849;
}

.button {
  border-radius: 3px !important;
}

.navbar.is-primary {
  background-color: #8fa849;
}

#navbarCartItemsCounter {
  background-color: white;
  color: black;
  border-left-color: #8fa849;
}

.has-background-primary-light {
  background-color: rgba(0, 160, 161, 0.1) !important;
}

.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
  background-color: hsl(0deg, 0%, 86%);
  color: rgba(0, 0, 0, 0.7);
}

.steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: #8fa849 !important;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow) {
  background-color: #8fa849;
}

.steps-segment:after {
  background-color: #8fa849;
}

/* Navbar */
nav.navbar {
  height: 80px;
}
nav.navbar .navbar-brand .navbar-item img {
  max-height: 42px;
  margin-bottom: 6px;
}

div .tabs {
  font-size: 0.9rem;
  height: auto !important;
}
div .tabs a:hover {
  border-bottom-color: #8fa849;
}
div .tabs .is-active {
  background-color: #8fa849;
}
div .tabs .is-active a {
  border-bottom: 0;
  color: white;
}

@media screen and (max-width: 768px) {
  .hero.header.start {
    height: 50vh !important;
  }
}