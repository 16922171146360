.fade-in {
  animation: fadeIn ease 2s forwards;
  -webkit-animation: fadeIn ease 2s forwards;
  -moz-animation: fadeIn ease 2s forwards;
  -o-animation: fadeIn ease 2s forwards;
  -ms-animation: fadeIn ease 2s forwards;
}

.fade-out {
  animation: fadeOut ease 2s forwards;
  -webkit-animation: fadeOut ease 2s forwards;
  -moz-animation: fadeOut ease 2s forwards;
  -o-animation: fadeOut ease 2s forwards;
  -ms-animation: fadeOut ease 2s forwards;
}

@keyframes fadeIn {
  0%    { opacity: 0;}
  100%  { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0%    { opacity: 0;}
  100%  { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0%    { opacity: 0;}
  100%  { opacity: 1; }
}

@-o-keyframes fadeIn {
  0%    { opacity: 0;}
  100%  { opacity: 1;}
}

@-ms-keyframes fadeIn {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}

@keyframes fadeOut {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}

@-moz-keyframes fadeOut {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}

@-webkit-keyframes fadeOut {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}

@-o-keyframes fadeOut {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}

@-ms-keyframes fadeOut {
  0%    { opacity: 1;}
  100%  { opacity: 0;}
}
